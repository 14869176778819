<template>
	<section class="section-wrapper" v-animate="'opacity'">
		<base-heading tag="h2" :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`"/>
		<base-container>
			<grid-template cols="4">
				<base-product-tile v-for="(category, index) in categories" :key="index" :category="category"/>
			</grid-template>
		</base-container>
	</section>
</template>
<script>
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import BaseProductTile from '../../components/atoms/BaseProductTile/BaseProductTile.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
export default {
	components: { GridTemplate, BaseProductTile, BaseHeading },
	props: {
		prefix: String,
		value: Object
	},
	data () {
		return {
			categories: []
		}
	},
	methods: {
		async getMenuProducts () {
			const menuValues = this.$app.page.layout.value.menu.items
			const findProductsLinks = menuValues.find(menuValue => menuValue.name.toLowerCase() === 'produkty')
			const productsLinks = findProductsLinks.children
			this.categories = productsLinks
		}
	},
	mounted () {
		this.getMenuProducts()
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    padding: 4rem 0;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
    }
}

::v-deep .grid-wrapper {

	a {
		gap: 0;
	}

	svg {
		margin-right: 3rem;
		min-width: 2rem;
	}

}
</style>
